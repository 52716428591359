import { SEPathNames } from "../routing";

/* eslint-disable max-len */
export default {
  translation: {
    locale: "sv",
    routing: {
      ...SEPathNames,
      term: "villkor",
    },
    routeNames: {
      home: "Översikt",
      transfers: "Överföringar",
      messages: "Meddelanden",
      card: "Kort",
      loan: "Lån",
      deposit: "Sparkonto",
      leasing: "Leasing",
      menu: "Meny",
    },
    validators: {
      required: "Ange {{- name, lower}}",
      email: "Din angivna e-postadress är inte giltig",
      phone: "Ogiltigt {{- name, lower}}",
      pidn: "Ditt angivna personnummer är inte giltigt",
    },
    invoice: {
      currentInvoice: "Aktuell faktura",
      overdueAmount: "Förfallet belopp",
      movedToDebtCollection: "Flyttad till inkasso",
      accountName: "Kontonamn",
      accountNumber: "Kontonummer",
      autogiro: "Autogiro",
      bankgiro: "Bankgiro",
      customerNumber: "Kundnummer",
      loadMore: "Se fler fakturor",
      disclaimer: `För att inte bli debiterad ränta, betalar du {{- amount }} denna månad. Summan inkluderar räntebärande köp,
        kontantuttag samt eventuella avgifter.`,
      downloadInvoice: "Ladda hem PDF",
      showInvoice: "Visa faktura",
      dueDate: "Förfallodatum",
      invoice: "Faktura",
      invoiceAndDate: "Faktura {{- date, lower}}",
      invoiceDate: "Fakturadatum",
      invoiceDetails: "Fakturadetaljer",
      invoiceNumber: "Fakturanummer",
      invoicePeriod: "Fakturaperiod",
      invoiceArchive: "Fakturaarkiv",
      amountToPay: "Belopp att betala",
      minimumPayment: "Lägsta belopp att betala",
      maximumPayment: "Högsta belopp att betala",
      ocr: "Referensnummer (OCR)",
      plusGiro: "plusGiro",
      stopped: "Fakturan är stoppad.",
      stopText: `Vi har mottagit underlag gällande din reklamation. Faktureringen har stoppats i väntan på
        resultatet av den externa prövningen. Vid frågor kontakta oss på telefonr {{- phoneNumber }}.`,
      total: "Hela kreditbeloppet",
      balanceAtDate: "Saldo per den",
      seeInvoice: "Se faktura",
      invoiceDownload: "Ladda hem faktura",
      status: "Status",
      titles: {
        cancelled: "Produkten är uppsagd, {{- date, lower}}",
        overdue: "Förfallen faktura, {{- date, lower}}",
        currentPlaceholder: "Aktuell faktura",
        current: "Aktuell faktura, {{- date, lower}}",
        extraPayment: "Önskar du betala extra?",
        paymentFreeMonth: "Önskar du dig betalningsfri månad?",
      },
      statuses: {
        due: "Obetald",
        cancel: "Annulleras",
        paid: "Betald",
        overdue: "Förfallen",
        replaced: "Ersatt",
        pending: "Inom respit",
      },
      emptyState: {
        header: "Inga resultat hittades!",
        text: "",
      },
    },
    card: {
      accountNumber: "Kontonummer",
      accountName: "Kontonamn",
      accountType: "Kontotyp",
      brokenCard: "Trasigt kort",
      credit: "Kredit",
      coApplicant: "Medlåntagare",
      card: "Kort",
      insurance: "Försäkring",
      lostCard: "Förlorat kort",
      autogiro: "Autogiro",
      pfm: "Betalningsfri månad",
      cardNumber: "Kortnummer",
      expiresAt: "Giltigt till och med",
      creditLimit: "Kreditgräns",
      orderCard: "Beställ kort",
      order: "Beställ",
      hasCard: "Kort finns",
      activateCard: "Aktivera kort",
      status: "Kortstatus",
      blockCard: "Spärra kort",
      availableCredit: "Tillgänglig kredit",
      usedCredit: "Utnyttjad kredit",
      creditAmount: "Kreditbelopp",
      getCreditInsurance: "Teckna kreditskydd - en försäkring för ditt kort",
    },
    transfer: {
      fromAccount: "Från konto",
      toAccount: "Till konto",
      amount: "Belopp",
      bank: "Bank",
      transferMoney: "Överför",
      accountNumber: "Kontonummer",
      addNewAccount: "Lägg till nytt konto",
      clearingNumber: "Clearingnummer",
      manageSavedAccounts: "Hantera sparade konton",
      addAccount: "Lägg till konto",
      availableAmount: "Tillgängligt belopp",
      ownedAccountsLabel: "Dina konton",
      savedAccountsLabel: "Dina sparade mottagare",
      newReceiverLabel: "Ny mottagare",
      addNewReceiver: "Lägg till mottagare",
      chooseReceiver: "Välj mottagare...",
      amountToTransfer: "Belopp att överföra",
      bankTransferFee: "Uttagsavgift",
      balanceAfterTransfer: "Disponibelt efter överföring",
      noAmountError: "Kom ihåg att ange belopp du vill överföra.",
      noFromAccountError: "Kom ihåg att välja det konto du vill överföra från.",
      noToAccountError: "Kom ihåg att välja det konto du vill överföra till.",
      saveToAccountError: "Kom ihåg att spara det nya mottagarkontot.",
      amountError: "Du kan inte föra över mer än det disponibla beloppet på kontot",
      newTransfer: "Gör en ny överföring",
      transferedAmount: "Överfört belopp",
      transferErrorHeader: "Något gick fel",
      transferErrorText: "Något gick fel. Försök igen senare",
      clearingNumberError: "Kontrollera att du angett korrekt clearingnummer.",
      accountNumberError: "Kontrollera att du angett korrekt kontonummer.",
      invalidTotalLengthError: "Du har fyllt i kontonummer på fel sätt, vänligen försök igen.",
      clearingStartsWithEightThenFiveDigitsError: "Clearingnummer som börjar på 8 har alltid 5 siffror",
      displayNameError: "Ange ett namn.",
      duplicateAccount: "Kontonumret finns redan i din lista med sparade mottagare.",
      accountName: "Kontonamn",
    },
    loan: {
      totalSum: "Total summa",
      informationLabel: "Lånedetaljer",
      amount: "Lånebelopp",
      credit: "Saldo",
      coAppliant: "Medlåntagare",
      insurance: "Låneskydd",
      directDebit: "Autogiro",
      number: "Lånenummer",
      terminationAmountError: "Något gick fel. Försök igen senare",
      paymentInfo: "Betalningsinformation",
      getLoanInsurance: "Teckna låneskydd - en försäkring för ditt lån",
      technicalIssueTitle: "Tekniska problem",
      technicalIssueText: "Vi har just problem med att hämta vissa kontotyper. Prova gärna igen senare.",
    },
    deposit: {
      accountDetailsLabel: "Kontouppgifter",
      accountBalanceLabel: "Saldo",
      availableAmountLabel: "Disponibelt Belopp",
      withdrawalTimeLabel: "Uttagstid",
      myDeposit: "Mitt spar",
      myDepositBankingDays: "1-3 bankdagar",
      myDepositPlus: "Mitt spar+",
      myDepositPlusBankingDays: "31 dagar",
      interestFeesLabel: "Räntor och avgifter",
      accumulatedInterestLabel: "Intjänad ränta i år",
      freeOfChargeLabel: "Avgift",
      freeOfCharge: "Avgiftsfritt",
      transferAccount: "Uttagskonto",
      chooseTransferAccount: "Välj uttagskonto",
      chooseReceiverOrCreate: "Välj sparat konto eller ange nytt",
      newReceiver: "Ny mottagare",
      accountInformation: "Kontoinformation",
      accruedInterest: "Intjänad ränta",
      tax: "Skatt",
      totalTransferAmount: "Totalt att utbetala",
      closingDate: "Avslutsdatum",
      closeAccount: "Avsluta konto",
      closeAccountConfirmation: "Ja, avsluta konto",
      closeAccountWarningText: "Är du säker på att du vill stänga ditt konto, denna åtgärd går inte att ångra.",
      receiverSuccessLabel: "Dina pengar kommer att betalas ut till",
      receiverAccount: "Uttagskonto",
      accountRedemptionErrorHeader: "Något gick fel",
      accountRedemptionErrorText: "Något gick fel. Försök igen senare",
      totalSavingAmount: "Totalt sparbelopp",
      FTDMaturityDateTemplateStringText: "<p><strong>Låst</strong> – Kontot är låst till och med: {maturityDate}</p>",
      FTDActivationDateTemplateStringText:
        "<p><strong>Aktiveras snart</strong> – Du kan lägga in pengar fram till och med: {activationDate}</p>",
      FTDMaturityDateTitle: "Förfallodatum",
      FTDActivationDateTitle: "Aktiveringsdatum",
      FTDDepositPageMaturityDateTemplateStringText:
        "<strong>Kontot är låst till och med {maturityDate}</strong><br>Dina pengar är låsta i X månader till en fast ränta (X%).",
      FTDDepositPageActivationDateTemplateStringText:
        "<strong>Du har {activationDaysLeftText} kvar att sätta in pengar</strong><br>Du kan fortfarande sätta in pengar på ditt konto fram till och med {activationDate}, efter det kommer kontot att låsas.",
      FTDDepositToAccountDisabledText:
        "Inga insättningar möjliga då aktiveringsperioden för det här kontot har utlöpt.",
      FTDDepositFromAccountDisabledText:
        "Aktiveringsperioden för det här kontot har utlöpt. Vill du ta ut pengarna innan bindningstiden är över vänligen kontakta kundtjänst.",
      FTDWithdrawalInfo:
        "Den fasta räntan är bunden till {maturityDate}, insättningar och uttag är låsta tills bindningstiden gått (/löpt) ut.",
      FTDActivationDaysLeftText: "{activationDaysLeft} dagar",
      FTDLastActivationDayText: "idag",
      bindingPeriod: "Bindningstid",
      earlyDebitFeeRateLabel: "Uttagsavgift (% av beloppet)",
      fee: "Avgift",
    },
    leasing: {
      licensePlate: "Regnr",
      leaseInformationLabel: "Information om hyresavtalet",
      accountNumberLabel: "Kontonummer",
      expirationDateLabel: "Första förfallodagen för hyra",
      rentalPeriodLabel: "Hyrestid",
      dealerLabel: "Återförsäljare",
      modelLicenseLabel: "Modell och regnr",
      interestFeesLabel: "Räntor och avgifter",
      aviFeeLabel: "Aviavgift",
    },
    misc: {
      customer: "Kund",
      chooseProduct: "Välj produkt",
      chooseACard: "Välj kort",
      chooseEmploymentStatus: "Välj anställningsform",
      fee: "Avgift",
      interest: "Ränta",
      interestFees: "Räntor och avgifter",
      effectiveInterest: "Effektiv ränta",
      nominalInterest: "Nominell ränta",
      title: "Net Bank",
      logout: "Logga ut",
      published: "Publicerad",
      name: "Namn",
      accountNumber: "Kontonummer",
      accountName: "Kontonamn",
      balance: "Saldo",
      date: "Datum",
      purchaseDate: "Köpdatum",
      dateRange: "Datumintervall",
      foreignAmount: "Belopp i annan valuta",
      exchangeRate: "Valutakurs",
      reservedAmount: "Reserverat belopp",
      amount: "Belopp",
      receiver: "Mottagare",
      accountingDate: "Bokföringsdag",
      transactionDate: "Transaktionsdag",
      interestDate: "Bokföringsdag",
      paymentDate: "Betalningsdag",
      fromAccount: "Från konto",
      message: "Meddelande",
      messages: "Meddelanden",
      ocrMessage: "OCR-nummer/meddelande",
      ocrNumber: "OCR-nummer",
      youHave: "Du har",
      unreadMessage: "oläst meddelande",
      unreadMessages: "olästa meddelanden",
      unpaidInvoice: "obetald faktura",
      unpaidInvoices: "obetalda fakturor",
      and: "och",
      search: "Sök",
      cancel: "Avbryt",
      apply: "Tillämpa",
      applyFor: "Ansök",
      amountFrom: "Från belopp",
      amountTo: "Till belopp",
      dateFrom: "Datum från",
      dateTo: "Datum till",
      selectDate: "Välj datum",
      clear: "Rensa",
      clearFilter: "Rensa filtret",
      select: "Välj",
      email: "E-post",
      mobile: "Mobilnummer",
      phone: "Telefonnummer",
      nameAdress: "Namn & adress",
      sms: "SMS",
      internetbank: "Internetbank",
      saved: "Sparad",
      updateInfo: "Uppdatera",
      downloadInfo: "Ladda ner",
      licensePlate: "Registreringsnummer",
      fetchMore: "Hämta fler",
      showLess: "Visa färre",
      yes: "Ja",
      no: "Nej",
      invoiceFee: "Aviavgift",
      bankTransferFee: "Kostnad banköverföring",
      atmFee: "Kostnad bankomatuttag",
      currencyExchangeFee: "Kostnad valutaväxling",
      status: "Status",
      active: "Aktivt",
      inactive: "Inaktiv",
      ssn: "Personnummer",
      storeName: "Beskrivning",
      atLeast: "Minst",
      upTo: "Upp till",
      exists: "Finns",
      missing: "Finns ej",
      blocked: "Spärrat",
      notActive: "Ej aktiverat",
      other: "Annat",
      send: "Skicka",
      delete: "Radera",
      save: "Spara",
      close: "Stäng",
      edit: "Ändra",
      seeTerms: "Se villkoren",
      saveAndUse: "Spara och använd",
      summary: "Sammanställning",
      terminate: "Avsluta",
      continue: "Fortsätt",
      years: "år",
      monthShort: "mån",
      with: "med",
      contractNumber: "Kontraktsnummer",
      directDebit: "Autogiro",
      document: "Dokument",
      headline: "Rubrik",
      done: "Klar",
      chooseDifferentDate: "Välj ett annat datum",
      calculate: "Räkna ut",
      termsButtonText: "Jag har läst och förstått villkoren",
      order: "Beställ",
      doesNotExist: "Finns ej",
      accountInformation: "Kontoinformation",
      accountDetails: "Kontouppgifter",
      nameAndDate: "Namn / datum",
      exportXLSX: "Exportera till kalkylblad (xlsx)",
      reserved: "Reserverad",
      outgoing: "På väg ut",
      multiSelectNoOptions: "Inga träffar",
      chooseCitizenshipPlaceholder: "Börja skriva, välj land i listan",
      choose: "Välg",
    },
    date: {
      periodOfTime: "Tidsperiod",
      last30: "Senaste 30 dagarna",
      last3Months: "Senaste 3 månaderna",
      thisYear: "Hittills i år",
      months: "månader",
    },
    emptyState: {
      header: "Inga resultat hittades!",
      text: "Försök igen senare!",
    },
    login: {
      header: "Logga in",
      text: "I Internetbanken gör du som privatkund enkelt dina bankärenden när du vill.",
      updateRequired: "Du måste ladda ner den senaste versionen av appen för att kunna logga in.",
      placeholder: "Ange personnummer",
      loginButtonTextMobile: "Mobilt BankID",
      loginButtonTextDesktop: "BankID på denna enhet",
      loginMobileBankId: "Logga in med Mobilt BankID",
      waitingForMobileBankID: "Väntar på svar från svar från Mobilt BankID",
      rememberMe: "Kom ihåg mig",
      mockData: "Mockdata",
      loginQRTitle: "Scanna QR-kod med BankID appen",
      verifyingTextDesktop: "Skriv in din säkerhetskod i BankID-appen. Klicka här om du behöver hjälp.",
      verifyingTextMobile: "För att logga in, starta din BankID applikation på din mobil.",
      bankIDLinkName: "här",
      santanderHomepageURL: "https://www.santanderconsumer.se",
    },
    message: {
      inbox: "Inkorg",
      archive: "Arkiv",
      toArchive: "Arkivera",
      selectAccountText: "Välj konto",
      selectSubjectText: "Välj ämne",
      selectAreaText: "Välj område",
      accountLabelText: "Välj ditt konto",
      subjectLabelText: "Välj ett ämne",
      areaLabelText: "Välj ett område",
      composeNewMessage: "Skriv nytt meddelande",
      newMessage: "Nytt meddelande",
      sendMessage: "Skicka meddelande",
      textareaPlaceholder: "Skriv ditt meddelande här",
      textareaReplyPlaceholder: "Klicka för att skriva ditt svar",
      attach: "Bilaga",
      faq: "Vanliga frågor",
      loadMore: "Se fler mail",
      reply: "Svara",
      prevDialog: "Tidigare dialog",
      backToInbox: "Tillbaka till inboxen",
      inboxEmpty: "Din inkorg är tom",
      productError: "Välj ett konto",
      subjectError: "Välj ett ämne",
      areaError: "Välj ett område",
      messageError: "Ange ett meddelande",
      fileSizeError: "Filen du försökte ladda upp var för stor",
      attachmentsError: "Maximalt antal bilagor har uppnåtts",
      goBack: "Gå tillbaka",
      sent: "Skickat",
    },
    placeholders: {
      chooseAccount: "Välj konto",
      year: "ÅÅÅÅ",
      month: "MM",
    },
    error: {
      required: "Fältet är obligatoriskt.",
      incorrectEmail: "Något blev fel. Titta efter så du fyllt i din e-postadress rätt.",
      conflictingEmail: "Vänligen ange en annan e-postadress.",
      tooShortPhone: "Mobilnummer måste vara minst 8 siffror.",
      tooLongPhone: "Mobilnummer får vara max 13 siffror.",
      conflictingPhone: "Vänligen ange ett annat telefonnummer.",
    },
    retryPage: {
      title: "Oj.",
      text: "Någonting gick fel. Prova att ladda om sidan.",
      reloadLink: "Ladda om",
    },
    langPicker: {
      label: "Välj språk",
      sv: "svenska",
      en: "English",
    },
  },
};
