import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStores } from "netbank-shared/src/hooks";
import { LoanTerminationCalcStep } from "./LoanTerminationFlow/LoanTerminationCalcStep";
import { LoanTerminationsStep } from "netbank-shared/src/libs/models/Content/Enums";
import { LoanTerminationPaymentStep } from "./LoanTerminationFlow/LoanTerminationPaymentStep";
import { ILoanTerminationsPage } from "netbank-shared/src/libs/models/Content/Page";
import { tx } from "netbank-shared/src/libs/i18n";
import { LoanTerminationConsentStep } from "./LoanTerminationFlow/LoanTerminationConsentStep";
import { ILoanAccountTerminationDetails } from "netbank-shared/src/libs/models/CustomerProducts";
import { SelfServiceFailurePage } from "../SelfServiceFailurePage";
import { getProductUrl } from "netbank-shared/src/libs/utils/url";
import { AccountActionContainer } from "~views/shared/AccountAction/AccountActionContainer";

export const LoanTerminationsPage = observer(() => {
  const { loanStore, contentStore } = useStores();
  const { currentPage } = contentStore;
  const { loanTerminationDetails, currentAccount } = loanStore;
  const pageData = currentPage as ILoanTerminationsPage;
  const details = loanTerminationDetails as ILoanAccountTerminationDetails;
  const paymentInfoRows: { data: string; label: string }[] = [];

  const [step, setStep] = React.useState<LoanTerminationsStep>(LoanTerminationsStep.CalcStep);

  const goToNextStep = () => {
    switch (step) {
      case LoanTerminationsStep.CalcStep:
        setStep(LoanTerminationsStep.ConsentStep);
        break;
      case LoanTerminationsStep.ConsentStep:
        setStep(LoanTerminationsStep.PaymentStep);
        break;
      default:
        setStep(LoanTerminationsStep.CalcStep);
    }
  };

  useEffect(() => {
    return () => {
      loanStore.resetLoanTerminationsState();
    };
  }, []);

  if (!currentAccount?.accountId) {
    return <SelfServiceFailurePage data={pageData.emptyState} />;
  }

  const renderTerminationFlow = () => {
    const goBackToAccount = getProductUrl(currentAccount.accountType, currentAccount.accountId);

    switch (step) {
      case LoanTerminationsStep.CalcStep:
        return <LoanTerminationCalcStep data={pageData} goToNextStep={goToNextStep} />;
      case LoanTerminationsStep.ConsentStep:
        return <LoanTerminationConsentStep data={pageData} goToNextStep={goToNextStep} />;
      case LoanTerminationsStep.PaymentStep:
        if (details.paymentInformation?.registrationNumber) {
          paymentInfoRows.push({
            data: details.paymentInformation?.registrationNumber,
            label: pageData.registrationNumberLabel || tx("loan.loanTerminations.registrationNumber"),
          });
        }

        if (details.paymentInformation?.accountNumber) {
          paymentInfoRows.push({
            data: details.paymentInformation?.accountNumber,
            label: pageData.accountNumberLabel || tx("loan.loanTerminations.accountNumber"),
          });
        }

        if (details.paymentInformation?.referenceNumber) {
          paymentInfoRows.push({
            data: details.paymentInformation?.referenceNumber,
            label: pageData.referenceNumberLabel || tx("loan.loanTerminations.referenceNumber"),
          });
        }

        return (
          <LoanTerminationPaymentStep
            data={pageData}
            paymentInfoRows={paymentInfoRows}
            goBackToAccountUrl={goBackToAccount}
            showExportButton={pageData.showExportButton}
          />
        );
      default:
        return <LoanTerminationCalcStep data={pageData} goToNextStep={goToNextStep} />;
    }
  };

  const pageInfo = {
    title: pageData.pageTitle,
    text: pageData.pageText,
    accountInfo: [
      {
        label: pageData.accountNameHeaderLabel || tx("misc.accountName"),
        value: currentAccount.name,
      },
      {
        label: pageData.displayNumberHeaderLabel || tx("invoice.invoice"),
        value: currentAccount.displayNumber,
      },
    ],
  };

  return (
    <AccountActionContainer info={pageInfo} isPage>
      {renderTerminationFlow()}
    </AccountActionContainer>
  );
});
